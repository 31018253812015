.navbar {
  height: 105px;
  background-color: #0d1273;
  display: flex;
  justify-content: center;
}

.navContainer {
  width: 100%;
  max-width: calc(100vw - 180px);
  color: #0d1273;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sections {
  font-size: 1.05rem;
  font-weight: 600;
  color: #02c8ce;
}

.sections.active {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 2px;
  text-decoration-color: #fcd367;
  text-underline-offset: 8px;
}

.navButton {
  margin-left: 20px;
  border: none;
  padding: 10px 25px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  color: #0d1273;
  background-color: #fcd367;
  border-radius: 0.5rem;
}

.login-form-enter {
  opacity: 0;
  transform: translateY(-50px);
}
.login-form-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 400ms, transform 400ms;
}

.login-form-exit {
  opacity: 1;
  transform: translateY(0);
}

.login-form-exit-active {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 400ms, transform 400ms;
}

.mobile-menu {
  display: none;
}

.mobile-menu-items {
  display: none;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  background-color: #0d1273;
  padding: 20px;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 10;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.mobile-menu-items.open {
  display: flex;
}

.mobile-menu-items a {
  display: block;
  padding: 10px 0;
}

.mobile-menu > .navButton{
  font-size: 1.2rem;
}

@media screen and (max-width: 1100px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu {
    display: flex;
  }
}