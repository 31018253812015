.progress-bar {
  height: 12px;
  border-radius: 25px;
  background-color: #227788;
}

.progress-bar-container {
  width: 100%;
  height: 12px;
  background-color: #d9d9d9;
  border-radius: 25px;
  position: relative;
  margin-bottom: 5%;
}

.progress-bar-container.fixed {
  left: 0;
  right:0;
  position: fixed;
  top: 15px;
  z-index: 999;
}

.progress-percentage {
  color: #227788;
  position: absolute;
  bottom: -30px;
  font-size: 18px;
  font-weight: 600;
}

.progress-logo {
  position: absolute;
  bottom: -6px;
  width: 25px;
  height: 25px;
}

.question-field {
    display: flex;
    justify-content: center;
}

.question-text {
    color: #38465E;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.answer-field {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
} 

.circle-container {
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
}

.circle {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    border: 3px solid #38465E;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.circle-container:nth-child(1) {
  padding-left: 50px;
  margin-right: -25px;
}

.circle-container:nth-child(2) {
  margin-left: -7px;
}

.circle-container:nth-child(4) {
  margin-right: -7px;
}

.circle-container:nth-child(5) {
  margin-left: -7px;
}

.circle-container:nth-child(1) .circle {
    margin-left: 52px;
  }

.circle-container:nth-child(2) .circle {
    margin-left: 14px;
  }
  
  .circle-container:nth-child(3) .circle {
    margin-left: 6px;
  }
  
  .circle-container:nth-child(4) .circle {
    margin-left: 2px;
  }
  
  .circle-container:nth-child(5) .circle {
    margin-left: 35px;
  }

  .circle-container.selected .circle {
    background-color: #38465E;
    transform: scale(1.2);
    transition: all 0.4s ease-in-out;
  }

.option-text {
    color: #38465E;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
    margin-right: 50px;
}

.survey-body {
    margin: 20px 0;
    padding: 20px;
    border-bottom: 3px solid #f4f2ec;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.next-button button, .back-button button,
.view-results-button button {
  display: inline-block;
  width: 200px;
  height: 65px;
  background-color: #38465e;
  color: #f2f2f2;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 25%;
}

.back-button {
  margin-right: 20%;
}


.next-button .error-message {
  color: #38465e;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}

.label-left,
.label-right {
  display: none;
}

@media only screen and (max-width: 900px) {
  .question-text {
    font-size: 1.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .option-text {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 700px) {
  .progress-bar {
    height: 10px;
  }
  .progress-bar-container {
    height: 10px;
  }
  .progress-percentage {
    font-size: 1rem;
  }
  .question-text {
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .option-text {
    display: none;
  }
  .answer-field-with-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .answer-field {
    gap: 0.5rem;
  }
  .label-left,
  .label-right {
    display: block;
    color: #38465E;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
  }
  .label-left {
    float: left;
    margin-left: 10px;
  }
  .label-right {
    float: right;
    margin-right: 10px;
  }
  .circle-container:nth-child(1) ,
  .circle-container:nth-child(2) ,
  .circle-container:nth-child(3) ,
  .circle-container:nth-child(4) ,
  .circle-container:nth-child(5) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }
  .circle-container:nth-child(1) .circle,
  .circle-container:nth-child(2) .circle,
  .circle-container:nth-child(3) .circle,
  .circle-container:nth-child(4) .circle,
  .circle-container:nth-child(5) .circle {
    margin-left: 0;
    margin-right: 0;
  }
  .circle {
    width: 30px;
    height: 30px;
  }
  .next-button button, .back-button button,
  .view-results-button button {
    width: 150px;
    height: 50px;
    font-size: 18px;
  }
}