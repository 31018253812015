.latest-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .latest-header {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    color: #fff;
  }
  
  .posts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 20px;
  }
  
  .post {
    flex: 0 0 calc(25% - 20px);
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
  }
  
  .post-image img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .post-content {
    padding: 10px;
  }
  