.result-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

canvas {
  width: 900px !important;
  height: 600px !important;
}

.resultTitle{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #227788;
}

.resultTitle h1 {
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 5%;
}

.resultTitle p {
  font-size: 1.5rem;
  font-weight: 400;
  padding-bottom: 5rem;
}

.chart-container{
  padding-bottom: 5rem;
}

.score-container {
  width: 100%;
  text-align: center;
  align-items: center;
  padding-top: 10rem;
  padding-bottom: 10rem;
  margin-bottom: 3rem;
  font-weight: 400;
  color: #38465E;
  background-color: #F2EEE2;
}

.score-container .row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 4rem;
  margin-left: 7%;
  margin-right: 7%;
}

.high-grade {
  color: #0D1273;
}

.low-grade {
  color: #227788;
}

.score-container .skill {
  font-size: 1.4rem;
  font-weight: 600;
}

.score-container .score {
  margin: 2.8rem;
  font-size: 3.4rem;
  font-weight: 600;
}

.score-container .grade {
  font-size: 1.4rem;
  font-weight: 600;
}

.score-container .overall-score .skill{
  font-size: 1.8rem;
}

.score-container .overall-score .score{
  font-size: 4.8rem;
}

.score-container .overall-score .grade{
  font-size: 1.8rem;
}

.explain-container {
  margin: 2%;
}

.categoryButton {
  outline: 'none';
  font-size: 25px;
  color: gray;
  background-color: transparent;
  border: none;
  margin-right: 50px;
  padding-bottom: 10px;
  text-decoration-skip-ink: none;
  cursor: pointer;
}

.categoryButton.selected {
  font-size: 3rem;
  color: #38465e;
  text-decoration: underline #FCD367 0.3rem;
  text-underline-offset: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .categoryButton {
    font-size: 1.5rem;
  }
  .categoryButton.selected {
    font-size: 2rem;
  }
}

.description{
  color: #38465E;
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-top: 2rem;
  margin-bottom: 5rem;
  margin-right: 4rem;
}

.explain{
  color: #38465E;
  margin: 3px;
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-left: 5rem;
  margin-right: 5rem;
}

.toDevelop{
  font-size: 2rem;
  font-weight: 600;
  font-style: italic;
  /* text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 3px;
  text-decoration-color: #FCD367;
  text-underline-offset: 8px; */
}

.explain-container ul {
  margin-right: 4rem;
}

.survey-link {
  text-align: center;
  color: #227788;
  font-size: 1.5rem;
  padding: 2rem;
  font-weight: 600;
  /* text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 3px;
  text-decoration-color: #FCD367;
  text-underline-offset: 8px; */
  cursor: pointer;
}

.survey-link > span{
  color: #FCD367;
}

@media only screen and (max-width: 1200px) {
  canvas {
    width: 800px !important;
    height: 500px !important;
  }
  
  .resultTitle h1 {
    font-size: 2rem;
  }

  .resultTitle p {
    font-size: 1.2rem;
    padding-bottom: 4rem;
  }

  .score-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .score-container .skill {
    font-size: 1.2rem;
  }

  .score-container .score {
    margin: 2rem;
    font-size: 2.5rem;
  }

  .score-container .grade {
    font-size: 1.2rem;
  }

  .score-container .overall-score .skill {
    font-size: 1.5rem;
  }

  .score-container .overall-score .score {
    font-size: 4rem;
  }

  .score-container .overall-score .grade {
    font-size: 1.5rem;
  }
  
  .explain-container {
    margin: 1%;
  }

  .description {
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 4rem;
  }

  .explain {
    font-size: 1.2rem;
    line-height: 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .toDevelop {
    font-size: 1.5rem;
  }

  .survey-link {
    font-size: 1.2rem;
    padding: 1rem;
  }
}

@media only screen and (max-width: 900px) {

  canvas {
    width: 650px !important;
    height: 400px !important;
  }

  .score-container .row {
    margin-left: 3%;
    margin-right: 3%;
  }

  .score-container .skill {
    font-size: 1rem;
  }

  .score-container .score {
    margin: 1.5rem;
    font-size: 2rem;
  }

  .score-container .grade {
    font-size: 1rem;
  }

  .score-container .overall-score .skill {
    font-size: 1.2rem;
  }

  .score-container .overall-score .score {
    font-size: 3rem;
  }

  .score-container .overall-score .grade {
    font-size: 1.2rem;
  }

  .explain-container {
    margin: 1%;
  }

  .description {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 3rem;
  }

  .explain {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .toDevelop {
    font-size: 1.2rem;
  }

  .survey-link {
    font-size: 1rem;
    padding: 0.5rem;
  }
}

@media only screen and (max-width: 900px) {

  canvas {
    width: 550px !important;
    height: 400px !important;
  }

  .score-container .skill {
    font-size: 0.6rem;
  }

  .score-container .score {
    margin: 1.3rem;
    font-size: 1.8rem;
  }

  .score-container .grade {
    font-size: 0.8rem;
  }

  .score-container .overall-score .skill {
    font-size: 1rem;
  }

  .score-container .overall-score .score {
    font-size: 2rem;
  }

  .score-container .overall-score .grade {
    font-size: 1rem;
  }
}