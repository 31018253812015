.mail{
  width: 87.5vw;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #0D1273;
  color: white;
  padding: 6%;
  gap: 20px;
  box-shadow: 2px 5px 5px #ddd;
}

.mailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.mailTitle{
  font-size: 2.2rem;
  font-weight: 600;
  padding: 20px;
}

.mailDesc{
  font-size: 1.2rem;
  font-weight: 400;
  padding-bottom: 10px;
}

.mailInputContainer>input{
  width: 300px;
  height: 30px;
  padding: 10px;
  border: none;
  margin-right: 10px;
  border-radius: 14px;
}

.mailInputContainer>input:focus{
  outline-color: #FBB702;
}

.mailInputContainer>button{
  height: 50px;
  background-color: #FBB702;
  color: black;
  font-weight: 500;
  border: none;
  border-radius: 14px;
  cursor: pointer;
}

h2 {
  color: #38465E;
}

@media only screen and (max-width: 700px) {
  .mailTitle{
    font-size: 1.5rem;
  }
  
  .mailDesc{
    font-size: 1rem;
  }
}