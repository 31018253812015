.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  width: 60%;
  height: 180px;
  color: #38465E;
}

.left-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right-side {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media only screen and (max-width: 900px) {
  .footer {
    width: auto;
  }
  .right-side {
    display: none;
  }
}

.footer-logo {
  height: 52px;
  width: auto;
}

.footer-text {
  margin: 10px 0;
}

.social-icons {
  margin-top: 5%;
  margin-right: auto;
  width: auto;
}

.social-icons .icon {
  height: 18px;
  margin: 0 6px;
}