.loginForm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 60%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.closeButton {
  position: absolute;
  color: #0d1273;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

.leftSection,
.rightSection {
  display: inline-block;
  width: 50%;
  height: 100%;
  vertical-align: top;
}

.leftSection {
  position: relative;
}

.leftSection::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: #f2eee2;
}

.logo2 {
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: 12rem;
  height: auto;
}

.illustration {
  position: absolute;
  top: 55%;
  left: 45%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: auto;
}

.formContainer {
  padding: 18%;
  color: black;
  max-height: 100%;
}

.formContainer p {
  color: black;
  size: 0.6rem;
  padding-bottom: 2rem;
}

.formContainer label {
  color: #666666;
  size: 1rem;
}

.registerLink {
  color: #0d1273;
  cursor: pointer;
}

.emailInput,
.passwordInput {
  display: block;
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(13, 18, 115, 0.5);
  border-radius: 5px;
  outline: none;
}

.passwordTooltip {
  margin-left: 5px;
  cursor: help;
}

.passwordTooltip:hover::after {
  content: "The password should contain at least one uppercase, one special character, and a minimum of 8 digits.";
  position: absolute;
  width: 300px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: 3rem;
}

.loginButton {
  display: block;
  align-items: center;
  width: 85%;
  padding: 10px;
  background-color: #0d1273;
  font-size: 1rem;
  font-weight: 300;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  margin-bottom: 20px;
}

.separator hr {
  flex-grow: 1;
  border: none;
  border-top: 2px solid #e7e4e4;
}

.separator span {
  color: #e7e4e4;
  margin: 0 10px;
}

.thirdPartyLogos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 85%;
}

.thirdPartyLogos img {
  width: 25px;
  height: auto;
  cursor: pointer;
}

input[type="checkbox"] {
  margin-right: 5px;
}

input[type="checkbox"]:checked {
  color: #0d1273;
}

.rememberMe,
.forgotPassword {
  font-size: 0.8rem;
  color: #666666;
}
