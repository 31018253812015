.header {
  background-color: #fbfaf6;
  color: white;
  display: flex;
  justify-content: center;
  position: relative;
  box-shadow: 0px 5px 5px #ddd, 0 -1px 2px #ddd;
}

.headerContainer {
  width: 100%;
  max-width: 1400px;
  margin: 1.5rem 1.5rem 5rem 1.5rem;
}

.headerTitle {
  margin: 30px;
  font-size: 34px;
  font-weight: 600;
  color: #227788;
  text-align: center;
  font-style: italic;
}

.headerDesc {
  margin: 30px 0px;
  padding-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  color: #227788;
  text-align: left;
}

.headerDesc > span {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 2px;
    font-weight: 800;
    text-decoration-color: #FCD367;
    text-underline-offset: 8px;
}

.headerDesc2 {
  margin: 20px 0px;
  font-weight: 400;
  font-size: 20px;
  color: #38465e;
  text-align: left;
}

.headerDesc2 > span {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-weight: 600;
  text-decoration-thickness: 2px;
  text-decoration-color: #FCD367;
  text-underline-offset: 8px;
}