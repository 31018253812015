.expandable-section {
    border: 1px solid #d6d5d4;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .section-content {
    padding: 1rem;
  }  

  .form-group {
    font-size: 1.3rem;
    font-weight: 600;
    color: #38465E;
    margin-bottom: 1rem;
  }
  
  .form-group label {
    margin-right: 1rem;
  }
   
  .form-control {
    font-size: 1.1rem;
    margin-top: 1rem;
    font-weight: 400;
  }

  .form-control input[type="radio"],
  .form-control input[type="checkbox"] {
    margin-bottom: 0.5rem;
  }
  
  .form-control label {
    margin-left: 0.5rem;
  }