.surveyHeader {
  width: 100%;
  height: auto;
  margin: -50px 0px 1rem 0px;
  background-color: #fbfaf6;
  color: white;
  display: flex;
  justify-content: center;
  position: relative;
  box-shadow: 0px 5px 5px #ddd, 0 -1px 2px #ddd;
}

.surveyHeaderContainer {
  width: 100%;
  max-width: 1400px;
  margin: 20px 0px 80px 0px;
}

.sectionContainer {
  width: 100%;
  max-width: 1400px;
}

.section-header {
    font-weight: 600;
}

.survey-back-button {
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  color: #227788;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.3s;
  margin-bottom: 2rem;
}

.survey-back-button:hover {
  color: #0d1273;
}

#dateOfBirth {
  color: #38465E;
  padding-left: 0.2rem;
  font-size: 0.9rem;
}

#location::placeholder {
  color: #38465E;
  padding-left: 0.2rem;
  font-size: 0.9rem;
}

#industry {
  color: #38465E;
  padding-left: 0.2rem;
  font-size: 0.9rem;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button {
  display: inline-block;
  width: 200px;
  height: 65px;
  background-color: #38465e;
  color: #f2f2f2;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  margin-top: 4rem;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  padding-top: 5rem;
  border-radius: 10px;
  text-align: center;
  width: 30%;
  height: 20%;
  background-color: white;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.back-to-results-button {
  align-items: center;
  width: 30%;
  padding: 1rem;
  background-color: #0d1273;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 2rem;
}